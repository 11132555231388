/**
 * 404 page
 *
 */

// IMPORTS
import React from "react";
import {Link} from "gatsby";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import styled from "styled-components";
import moment from "moment";
import {GlobalStyle, NavigationHeader, ConsentBanner, ContactFooter, Caret} from "../components";
import {
  DflRegionType,
  NavigationOverlayStateType,
  navigationOverlayStates,
  px,
  stage,
  CenteredDiv,
  colors,
  bigRegular,
  smallBold,
  breakpoints,
  MainContentWrapper,
} from "../models";
import {getDflRegionByTimezone} from "../util/util";
import {AppState} from "../store/";
import {
  setTimezoneDetectedAction,
  updateRegionAction,
  updateNavigationOverlayAction,
  updateIsNavigationInvertedAction,
  updateUTCOffsetAction,
  updateIsHomepageAboveVideoAction,
} from "../store/system/actions";

// COMPONENT PROPERTIES
interface PropsFromState {
  selectedRegion: DflRegionType;
  timezoneDetected: boolean;
  isNavigationHidden: boolean;
}
const mapStateToProps = (state: AppState): PropsFromState => ({
  selectedRegion: state.system.selectedRegion,
  timezoneDetected: state.system.timezoneDetected,
  isNavigationHidden: state.system.isNavigationHidden,
});

interface PropsFromDispatch {
  updateRegion: (region: DflRegionType) => void;
  setTimezoneDetected: () => void;
  updateUTCOffset: (newOffset: number) => void;
  updateNavigationOverlay: (newState: NavigationOverlayStateType) => void;
  updateNavigationInverted: (isInverted: boolean) => void;
  updateIsHomepageAboveVideo: (isInverted: boolean) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateRegion: (region: DflRegionType) => {
      return dispatch(updateRegionAction(region));
    },
    setTimezoneDetected: () => {
      return dispatch(setTimezoneDetectedAction());
    },
    updateUTCOffset: (newOffset: number) => {
      return dispatch(updateUTCOffsetAction(newOffset));
    },
    updateNavigationOverlay: (newState: NavigationOverlayStateType) => {
      return dispatch(updateNavigationOverlayAction(newState));
    },
    updateNavigationInverted: (isInverted: boolean) => {
      return dispatch(updateIsNavigationInvertedAction(isInverted));
    },
    updateIsHomepageAboveVideo: (isInverted: boolean) => {
      return dispatch(updateIsHomepageAboveVideoAction(isInverted));
    },
  };
};

// STYLES
const PageWrapper = styled.div`
  height: 100vh;
  overflow: visible;
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Content = styled.div`
  width: 100%;
  margin: 0;
  padding: 0 22px;
  box-sizing: border-box;

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    width: ${px(stage.innerWidth)};
    margin: 0 auto;
    padding: 0;
  }
`;
const Message = styled.h1`
  ${bigRegular};
  font-size: 30px;
  margin-top: 110px;
  margin-bottom: 30px;

  /* tablet */
  @media (min-width: ${px(breakpoints.tablet)}) {
    font-size: 40px;
  }

  /* desktop */
  @media (min-width: ${px(breakpoints.desktopHd)}) {
    font-size: 50px;
    margin-top: 220px;
    margin-bottom: 60px;
  }
`;
const IconLink = styled(props => <Link {...props} />)`
  ${smallBold};
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    color: ${colors.redDFL};
    text-decoration: underline;
  }
`;
// @ts-ignore   //  TODO: Fehler mit styled() ?
const CaretWrapper = styled(CenteredDiv)`
  width: 8px;
  height: 8px;
  margin-right: 4px;
  transform: rotate(-90deg);
`;

const FooterWrapper = styled.div`
  margin-top: 96px;
`;

// COMPONENT
interface ProductTemplateProps extends PropsFromState, PropsFromDispatch {}
class ProductTemplate extends React.Component<ProductTemplateProps> {
  constructor(props: ProductTemplateProps) {
    super(props);
  }

  // Product category page initialization
  componentDidMount() {
    // get Timezone and utc offset once
    if (!this.props.timezoneDetected) {
      const dflRegion = getDflRegionByTimezone();
      this.props.updateRegion(dflRegion);
      if (moment && moment().utcOffset() !== null) {
        // ignore daylight savings time
        const isDayLightSavings = moment().isDST();
        const dayLightSavingsOffset = isDayLightSavings ? 1 : 0;
        this.props.updateUTCOffset(moment().utcOffset() / 60 - dayLightSavingsOffset);
      }
      this.props.setTimezoneDetected();
    }

    // set navigation to black (could be inverted, coming from landing page)
    this.props.updateNavigationInverted(false);
    this.props.updateIsHomepageAboveVideo(false);

    // hide overlay
    this.props.updateNavigationOverlay(navigationOverlayStates.default);
  }

  render() {
    return (
      <PageWrapper>
        <GlobalStyle />
        <NavigationHeader />
        <ConsentBanner />
        <MainContentWrapper>
          <ContentWrapper>
            <Content>
              <Message>The requested page could not be found.</Message>
              <IconLink to="/">
                <CaretWrapper>
                  <Caret color={colors.redDFL} />
                </CaretWrapper>
                To the home page
              </IconLink>
            </Content>
            <FooterWrapper>
              <ContactFooter />
            </FooterWrapper>
          </ContentWrapper>
        </MainContentWrapper>
      </PageWrapper>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductTemplate);
